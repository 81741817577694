import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { gql, useLazyQuery } from '@apollo/client';
import ClickOutside from 'ls-common-client/src/components/ClickOutside';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Text from 'ls-common-client/src/components/Text';
import Container from 'ls-common-client/src/components/Container';
import Icon from 'ls-common-client/src/components/Icon';
import styled from 'styled-components';
import { Input } from '../../../../common';
import SearchLabel from '../SearchLabel';
import isEmail from '../../../../../lib/isEmail';

const abnQuery = gql`
  query ProfileSuggest($query: String!) {
    profileSuggest(query: $query) {
      edges {
        node {
          id
          displayName
          suburb
        }
      }
    }
  }
`;

const StyledAutoSuggest = styled(Container)`
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  background: ${props => props.theme.background.background100};
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  padding: 0;
  z-index: 10;
  overflow: hidden;
`;

const StyledAutoSuggestItem = styled(EmptyButton)`
  display: block;
  width: 100%;
  margin: 0 !important;
  text-align: left;
  height: auto !important;
  &.isOffline {
    color: ${props => props.theme.text.text300};
  }
  &:hover,
  &.isOffline:hover {
    background: ${props => props.theme.primary.primary100};
  }
`;

const BusinessSuggest = ({ value, setValue }) => {
  const [fetchBusinessSuggest, { data: businessData }] = useLazyQuery(abnQuery);

  const [show, setShow] = useState(false);
  const [keyword, setKeyword] = useState('');

  const businesses = useMemo(() => {
    return businessData &&
      businessData.profileSuggest &&
      businessData.profileSuggest.edges
      ? businessData.profileSuggest.edges.map(edge => edge.node)
      : [];
  }, [businessData]);

  const fetch = useCallback(() => {
    if (keyword && !isEmail(keyword)) {
      fetchBusinessSuggest({
        variables: {
          query: keyword,
        },
      });
    }
  }, [keyword, fetchBusinessSuggest]);

  const onChange = e => {
    const { value: val } = e.target || {};
    setKeyword(val || '');
    setValue({
      name: 'searchValue',
      value: val,
    });

    if (val.length > 2) {
      fetch();
    }
  };

  const onSuggestClick = id => {
    setKeyword('');
    setValue({
      name: 'searchValue',
      value: id.toString(),
    });
    setShow(false);
  };

  return (
    <ClickOutside
      onClickOutside={() => setShow(false)}
      style={{ maxWidth: '300px' }}
    >
      <SearchLabel
        display="flex"
        flexDirection="row"
        className="withIcon fullWidth"
        position="relative"
      >
        <Icon
          className="ls-icon icon-generalsearchlarge"
          iconSize="31px"
          iconColor="dark"
        />
        <Input
          value={value}
          onChange={onChange}
          onFocus={() => setShow(true)}
          name="searchKeyword"
          padding="small"
          minLength="2"
          type="text"
          placeholder="Business name, profileID or email"
          marginBottom="xsmall"
          flex="1"
        />
        {show && keyword && businesses.length > 0 && (
          <StyledAutoSuggest>
            {businesses.map(({ id, displayName, suburb }) => (
              <StyledAutoSuggestItem
                padding="normal"
                type="button"
                key={id}
                onClick={() => onSuggestClick(id)}
              >
                <Text display="block">{displayName}</Text>
                <Text fontSize="small" color="text600">
                  {`${id} - ${suburb}`}
                </Text>
              </StyledAutoSuggestItem>
            ))}
          </StyledAutoSuggest>
        )}
      </SearchLabel>
    </ClickOutside>
  );
};

BusinessSuggest.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setValue: PropTypes.func,
};

BusinessSuggest.defaultProps = {
  value: undefined,
  setValue: () => {},
};

export default BusinessSuggest;
